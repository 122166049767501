package tripper.components

import androidx.compose.runtime.*
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import tripper.*
import tripper.dto.Language
import kotlin.text.Typography.nbsp

@Composable
fun LanguagePicker(
  viewModel: LocaleViewModel = LocalDependencies.current.localeViewModel,
  messages: Messages = rememberMessages(),
) {  
  val locale by viewModel.locale.collectAsState()
  var expanded by remember { mutableStateOf(false) }
  
  Span({
    classes("language-picker")
    onClick { expanded = !expanded }
  }) {
    GoogleIcon(Icons.Language, style { fontSize(1.25.cssRem) })
    Text(nbsp + messages.languageOption(locale.language) + nbsp)
    GoogleIcon(Icons.ExpandMore, style { fontSize(1.25.cssRem) })
    DropdownMenu(expanded, offset = DpOffset(0.dp, 5.dp), onDismissRequest = { expanded = false }) {
      Language.entries.forEach { language ->
        if (language != locale.language) {
          DropdownMenuItem(modifier {
            onClick {
              viewModel.setLanguage(language)
              expanded = false
              it.stopPropagation()
            }
          }) {
            Text(messages.languageOption(language))
          }
        }
      }
    }
  } 
}