package tripper.components

import androidx.compose.runtime.*
import io.ktor.http.ContentType.*
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import tripper.modifier
import tripper.style
import tripper.trips.ImagesViewModel
import kotlin.math.min
import org.w3c.dom.Image as HtmlImage

@Composable
fun ImageGallery(
  imagesViewModel: ImagesViewModel,
  editable: Editable? = null,
) = Div({ classes("image-gallery") }) {
  val images by imagesViewModel.images.collectAsState()
  if (images.isNotEmpty()) {
    Column(modifier { classes("images") }) {
      remember { images.forEach { HtmlImage().apply { src = it.src.value } } }
      var shownIndex by remember { mutableStateOf(0) }
      var showNavigation by remember { mutableStateOf(false) }
      var overlay by remember { mutableStateOf(false) }

      if (overlay) {
        Overlay(onClick = { overlay = false }, onDismissRequest = { overlay = false }) {
          if (images.size > 1) {
            GoogleIcon(Icons.NavigateBefore, modifier {
              classes("prev")
              onClick { shownIndex = (shownIndex + images.size - 1) % images.size }
            })
          }
          Spacer(style { flexGrow(1) })
          Img(images[shownIndex].src.value)
          Spacer(style { flexGrow(1) })
          if (images.size > 1) {
            GoogleIcon(Icons.NavigateNext, modifier {
              classes("next")
              onClick { shownIndex = (shownIndex + 1) % images.size }
            })
          }
          GoogleIcon(Icons.Close, modifier {
            classes("close")
            onClick {
              overlay = false
            }
          })
        }
      }

      Column(modifier {
        classes("shown")
        onMouseOver { showNavigation = images.size > 1 }
        onMouseOut { showNavigation = false }
      }) {
        if (showNavigation) {
          GoogleIcon(Icons.NavigateBefore, modifier {
            classes("prev")
            onClick { shownIndex = (shownIndex + images.size - 1) % images.size }
          })
        }
        Img(images[shownIndex].src.value) {
          attr("loading", "lazy")
          attr("decoding", "async")
          onClick { overlay = true }
        }
        if (editable != null && images[shownIndex].uploaded) {
          GoogleIcon(Icons.Delete, modifier {
            classes("delete")
            onClick { imagesViewModel.delete(images[shownIndex]) }
          })
        }
        if (showNavigation) {
          GoogleIcon(Icons.NavigateNext, modifier {
            classes("next")
            onClick { shownIndex = (shownIndex + 1) % images.size }
          })
        }
      }

      if (images.size > 1) {
        var firstThumbnailIndex by remember { mutableStateOf(0) }
        Column(modifier { classes("thumbnails") }) {
          val thumbnailsCount = 3
          if (images.size > thumbnailsCount) {
            GoogleIcon(Icons.ExpandLess, modifier {
              classes("prev")
              onClick { firstThumbnailIndex = (firstThumbnailIndex + images.size - 1) % images.size }
            })
          }
          val thumbnailIndexes = remember(firstThumbnailIndex) {
            val windowSize = min(3, images.size)
            List(windowSize) { (firstThumbnailIndex + it) % images.size }
          }
          thumbnailIndexes.forEach { index ->
            Row(modifier { classes("container") }) {
              Img(images[index].src.value) {
                attr("loading", "lazy")
                attr("decoding", "async")
                onClick {
                  shownIndex = index
                }
              }
              if (editable != null && images[index].uploaded) {
                GoogleIcon(Icons.Delete, modifier {
                  classes("delete")
                  onClick { imagesViewModel.delete(images[index]) }
                })
              }
            }
          }
          if (images.size > thumbnailsCount) {
            GoogleIcon(Icons.ExpandMore, modifier {
              classes("next")
              onClick { firstThumbnailIndex = (firstThumbnailIndex + 1) % images.size }
            })
          }
        }
      }
    }
  }
  if (editable != null && images.size < editable.maxNumber) {
    FileField(editable.uploadLabel, imagesViewModel, accept = Image.Any, validateFile = { imageSize(it.size) })
  }
}

data class Editable(val uploadLabel: String, val maxNumber: Int)