package tripper

import androidx.compose.runtime.*

fun disposable(block: DisposableEffectResult.() -> Unit = {}) = object : DisposableEffectResult {
  override fun dispose() {
    block()
  }
}

operator fun <T> State<T>.component1() = value

@Composable
fun <T> rememberPrev(current: T, shouldUpdate: (T) -> Boolean): T {
  var previous by remember { mutableStateOf(current) }
  SideEffect {
    if (shouldUpdate(current)) previous = current
  }
  return previous
}