package tripper.favorites

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import tripper.Loading
import tripper.Loading.None
import tripper.LocalDependencies
import tripper.comments.ContentId
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.load
import tripper.untilLoaded
import tripper.users.UserRef

class FavoriteViewModel(
  private val contentId: ContentId,
  private val userId: UserRef.Id,
  private val service: FavoriteService,
  private val scope: SafeCoroutineScope,
) {
  private val _favorite = MutableStateFlow<Loading<Favorite?>>(None)
  val favorite = _favorite.asStateFlow()
  
  init {
    scope.launch { 
      _favorite.load { service.favorite(contentId) }
    }
  }
  
  fun addToFavorite() {
    scope.launch { 
      _favorite.load { service.save(Favorite(contentId, userId)) }
    }
  }
  
  fun removeFromFavorite() {
    val favorite = favorite.value.untilLoaded(null) ?: return
    scope.launch { 
      _favorite.load {
        service.delete(favorite)
        null
      }
    }
  }
  
  companion object {
    @Composable
    fun remember(contentId: ContentId, userId: UserRef.Id): FavoriteViewModel {
      val favoriteService = LocalDependencies.current.favoriteService
      val scope = rememberCoroutineScope()
      return remember { FavoriteViewModel(contentId, userId, favoriteService, scope) }
    }
  }
}