package tripper.components

import androidx.compose.runtime.*
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import tripper.*
import tripper.auth.AuthAction.LoginGoogle
import tripper.auth.AuthAction.Logout
import tripper.auth.AuthViewModel
import tripper.lib.google.GoogleAuth
import tripper.lib.vk.VkAuth
import tripper.navigation.Pages

@NoLiveLiterals
@Composable
fun AuthPanel(
  googleAuth: GoogleAuth = remember { GoogleAuth() },
  vkAuth: VkAuth = VkAuth.create(),
  messages: Messages = rememberMessages(),
  pages: Pages = LocalDependencies.current.pages,
  authViewModel: AuthViewModel = LocalDependencies.current.authViewModel,
) = Span({ classes("auth-panel") }) {
  val viewModel = LocalDependencies.current.authViewModel
  
  if (rememberIsLoggedIn()) {
    val selfLoading = rememberSelfLoading()
    var menuShown by remember { mutableStateOf(false) }
    Avatar(selfLoading.untilLoaded(null), modifier {
      onClick { menuShown = !menuShown }
    })

    DropdownMenu(menuShown, offset = DpOffset(0.dp, 5.dp), onDismissRequest = { menuShown = false }) {
      selfLoading.onLoaded { self ->
        if (self != null) {
          DropdownMenuItem(link = pages.profile.path(self)) {
            Text(messages.profile())
          }
        }
      }
      DropdownMenuItem(modifier {
        onClick { viewModel.onAction(Logout) }
      }, enabled = false) {
        Text(messages.logout())
      }
    }
  } else {
    var authProvidersShown by remember { mutableStateOf(false) }
    GoogleIcon(Icons.Login, modifier {
      classes("login")
      onClick { authProvidersShown = !authProvidersShown }
    })
    DropdownMenu(authProvidersShown, onDismissRequest = { authProvidersShown = false }, DpOffset(0.dp, 5.dp), modifier { classes("login-menu") }) {
      DropdownMenuItem {
        Img("/public/vk.svg") {
          classes("vk")
          onClick { vkAuth.login() }
        }
      }
      DropdownMenuItem {
        Div({ classes("google") }) {
          DisposableEffect(Unit) {
            googleAuth.render(scopeElement, onLogin = { authViewModel.onAction(LoginGoogle(it)) })

            onDispose { }
          }
        }
      }
    }
  }
}