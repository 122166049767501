package tripper.components

import androidx.compose.runtime.*
import org.jetbrains.compose.web.dom.Main
import org.lighthousegames.logging.KmLogging
import tripper.*
import tripper.lib.js.import
import tripper.navigation.JsNavigation
import tripper.navigation.Navigator
import tripper.notifications.Notifications
import tripper.search.SearchResult
import tripper.trips.EditTrip
import tripper.trips.TripView
import tripper.trips.Trips
import tripper.users.UserRef

@Composable
fun Application(
  dependencies: Dependencies = Dependencies.create(JsLocalStorage(), JsNavigation()),
) {
  var cssLoaded by remember { mutableStateOf(false) }
  LaunchedEffect(Unit) {
    import("./styles/app.scss").then<dynamic> { cssLoaded = true }
    KmLogging.addLogger(BackendLogger(dependencies.httpClient, this))
  }
  CompositionLocalProvider(
    LocalDependencies provides dependencies,
    CssLoaded provides cssLoaded,
  ) {

    GlobalExceptionHandler()
    Notifications()

    Header()

    Main({ classes("body") }) {
      val scrollAnchor = ElementScopeWrapper(this).rememberScrollAnchor()
      Navigator { page, arguments, self ->
        when (page) {
          home -> trips.open()
          createTrip -> EditTrip(null, self!!)
          editTrip -> EditTrip(arguments["id"], self!!)
          trip -> TripView(arguments["id"], scrollAnchor, scrollToComments = "comments" in arguments)
          trips -> Trips()
          profile -> Profile(arguments.optional("nickname")?.let(UserRef::Nickname) ?: UserRef.Id(arguments["id"]))
          search -> SearchResult(arguments["q"])
          followings -> Trips(followings = true)
          favorites -> Trips(favorites = true)
          else -> NotFound()
        }
      }

      RequestLoadingBar()
    }
  }
}

val CssLoaded = compositionLocalOf { false }