package tripper.users

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import io.ktor.http.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import org.lighthousegames.logging.KmLog
import org.lighthousegames.logging.logging
import tripper.LocalDependencies
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.domain.Url
import tripper.files.*

class AvatarViewModel(
  initial: Url?,
  private val onUpload: (Url) -> Unit,
  private val isLoading: MutableStateFlow<Boolean>,
  private val imageConverter: ImageConverter,
  private val fileClient: FileClient,
  private val scope: SafeCoroutineScope,
  private val log: KmLog = logging(AvatarViewModel::class.simpleName),
): FileViewModel {
  private val _avatarUrl = MutableStateFlow(initial)
  val avatarUrl = _avatarUrl.asStateFlow()

  override fun upload(file: ByteArray, name: String, type: String) {
    isLoading.update { true }
    scope.launch {
      val current = _avatarUrl.value
      try {
        val (compressedFile, newName, newType) = imageConverter.compress(FileData(file, name, ContentType.parse(type)))
          .onPreLoad { preLoaded -> _avatarUrl.update { preLoaded } }
          .onCompressed { compressed -> _avatarUrl.update { compressed } }
          .result()
        onUpload(fileClient.upload(compressedFile, newName, newType).src)
      } catch (e: Throwable) {
        log.error(e) { "Image upload failed" }
        _avatarUrl.update { current }
      } finally {
        isLoading.update { false }
      }
    }
  }
  
  companion object {
    @Composable
    fun remember(initial: Url?, isLoading: MutableStateFlow<Boolean>, onUpload: (Url) -> Unit): AvatarViewModel {
      val imageConverter = rememberImageConverter()
      val fileClient = LocalDependencies.current.fileClient
      val scope = rememberCoroutineScope()
      return remember { AvatarViewModel(initial, onUpload, isLoading, imageConverter, fileClient, scope) }
    }
  }
}