package tripper.components

import androidx.compose.runtime.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import tripper.dto.Locale
import tripper.json
import tripper.lib.toastui.Viewer
import tripper.lib.toastui.ViewerOptions
import tripper.rememberLocale

@Composable
fun MarkdownViewer(
  text: String,
  expandable: Boolean = false,
  onClick: () -> Unit = {},
  locale: Locale = rememberLocale(),
  createViewer: (ViewerOptions) -> Viewer = { Viewer(it) },
) {
  var viewerExpanded by remember { mutableStateOf(!expandable) }
  Div({
    classes("markdown-viewer")
    if (!viewerExpanded) classes("collapsed")
    onClick { onClick() }
  }) {
    var viewer by remember { mutableStateOf<Viewer?>(null) }
    DisposableEffect(Unit) {
      viewer = createViewer(json {
        el = scopeElement
        language = locale.languageTag
        usageStatistics = false
      })
      onDispose { viewer?.destroy() }
    }
    viewer?.setMarkdown(text)
  }
  if (expandable) {
    Button({
      onClick { viewerExpanded = !viewerExpanded }
    }) {
      Text(if (viewerExpanded) "-" else "+")
    }
  }
}