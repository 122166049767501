package tripper.search

import androidx.compose.runtime.*
import org.lighthousegames.logging.KmLog
import org.lighthousegames.logging.logging
import tripper.*
import tripper.components.*
import tripper.followings.FollowButton
import tripper.navigation.Pages
import tripper.trips.Trip
import tripper.trips.TripCardView
import tripper.trips.ViewControls
import tripper.users.User
import tripper.users.ref

@Composable
fun SearchResult(
  query: String,
  searchViewModel: SearchViewModel = LocalDependencies.current.searchViewModel,
  messages: Messages = rememberMessages(),
  log: KmLog = remember { logging("SearchResult") },
) = Section(modifier { classes("search-result", "trips") }) {
  val resultsLoading by searchViewModel.results.collectAsState()
  var page by remember { mutableStateOf(0) }
  resultsLoading.fold(
    onLoaded = { results ->
      if (results.isNotEmpty()) {
        InfiniteScrollList(
          items = results,
          key = { it.searchKey },
          loadMore = { searchViewModel.loadMore(query, page++) },
          loadMoreIndex = (results.size - 5).coerceAtLeast(0),
        ) { item ->
          when(item) {
            is Trip -> TripCardView(item, short = true, controls = ViewControls(item, short = true)) 
            is User -> UserCard(item)
            //todo log it at server side
            else -> log.error { "Unknown search result type ${item::class.simpleName}" }
          }
        }
      } else {
        Row(modifier { classes("empty-result") }) {
          Text(messages.noSearchResults())
        }
      }
    },
    onNone = {
      searchViewModel.invokeSearch(query)
    }
  )
}

@Composable
fun UserCard(user: User, pages: Pages = LocalDependencies.current.pages) = Card(modifier { classes("user") }) {
  Avatar(user)
  Column(modifier { classes("content") }) { 
    Row(modifier { classes("identity") }) {
      Row(modifier { classes("full-name") }) { Text("${user.firstName} ${user.lastName}") }
      Row(modifier { classes("ref") }) { Text("@${user.ref}") }
    }
    Divider()
    Row(modifier { classes("controls") }) {
      val self = rememberSelfLoading().untilLoaded(null)
      if (self != null) FollowButton(user, self)
      Link(pages.profile.path(user)) {
        GoogleIcon(Icons.ArrowRightAlt)
      }
    }
  }
}