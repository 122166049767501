package tripper.trips

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.dom.Text
import tripper.*
import tripper.comments.Comments
import tripper.components.*
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.dadata.MOSKOW
import tripper.domain.Coords
import tripper.domain.WayPoint
import tripper.favorites.FavoriteButton
import tripper.followings.FollowButton
import tripper.lib.js.Intl
import tripper.lib.js.format
import tripper.lib.ymaps.YandexMap
import tripper.lib.ymaps.YandexMapHandler
import tripper.lib.ymaps.rememberYandexMapHandler
import tripper.likes.LikeButton
import tripper.navigation.Pages
import tripper.users.UserRef
import tripper.users.UserViewModel
import tripper.users.ref

@Composable
fun TripView(
  id: String,
  scrollAnchor: ScrollAnchor,
  scrollHandler: ScrollHandler = remember { ScrollHandler(scrollAnchor) },
  scrollToComments: Boolean = false,
  viewModel: TripViewModel = TripViewModel.create(id),
  createUserViewModel: @Composable (UserRef.Id) -> UserViewModel = { UserViewModel.remember(it) },
  scope: SafeCoroutineScope = rememberCoroutineScope(),
) {
  val tripLoading by viewModel.trip.collectAsState()
  val trip = tripLoading.untilLoaded(null) ?: return
  TripPage(card = {
    TripCardView(trip, short = false, controls = ViewControls(trip, short = false), createUserViewModel(trip.authorId))
  }, comments = {
    Comments(
      trip.contentId,
      modifier = modifier { scrollHandler(scrollHandler) },
      onLoaded = { if (scrollToComments) scope.launch { scrollHandler.scrollToTop() } },
    ) 
  })
}

@Composable
fun TripCardView(
  trip: Trip,
  short: Boolean,
  controls: List<@Composable TripCardScope.() -> Unit> = emptyList(),
  userViewModel: UserViewModel = UserViewModel.remember(trip.authorId),
  yandexMapHandler: YandexMapHandler = rememberYandexMapHandler(),
) {
  TripCard(
    trip, 
    header = {
      Column(modifier { classes("title") }) {
        Text(trip.title)
      }
      Author(userViewModel)
    },
    map = {
      YandexMap(yandexMapHandler, Coords(MOSKOW.latitude, MOSKOW.longitude), MOSKOW.zoom, emptyList(), scrollZoom = !short, onPointClick = {
        if (!short) scrollToPointIndex = it
      })
      remember { yandexMapHandler.setRoute(trip.wayPoints, focusedPointIndex.takeIf { !short }) }
      if (!short) {
        remember(focusedPointIndex) { 
          yandexMapHandler.focusPoint(focusedPointIndex)
          trip.wayPoints.getOrNull(focusedPointIndex)?.location?.let { yandexMapHandler.panTo(it.coords) }
        }
      }
    },
    summary = {
      trip.summary?.let {
        Row(modifier { classes("summary") }) {
          Text(it)
        }
      }
    },
    route = {
      Route(trip.wayPoints, short, wayPoint = { _, wayPoint ->
        WayPoint(wayPoint, short)
      })
    },
    controls = controls,
  )
}

@Composable
fun Author(userViewModel: UserViewModel, pages: Pages = LocalDependencies.current.pages) {
  Column(modifier { classes("author") }) {
    Divider()
    val authorLoading by userViewModel.user.collectAsState()
    authorLoading.onLoaded { author ->
      Link(pages.profile.path(author)) {
        Avatar(author)
      }
      Column(modifier { classes("controls") }) {
        Link(pages.profile.path(author)) {
          Text("@${author.ref}")
        }
        rememberSelfLoading().onLoaded { self ->
          if (self != null) FollowButton(author, self)
        }
      }
    }
  }
}

@Composable
fun ViewControls(
  trip: Trip,
  short: Boolean,
  pages: Pages = LocalDependencies.current.pages,
  messages: Messages = rememberMessages(),
): List<@Composable TripCardScope.() -> Unit> = buildList {
  val selfLoading = rememberSelfLoading()
  val self = selfLoading.untilLoaded(null)
  if (!short && self == null) return@buildList

  if (short) {
    add {
      Link(pages.trip.path(trip, true)) {
        GoogleIcon(Icons.Comment, modifier { classes("comments") })
      }
    }
  }
  self?.let { self ->
    if (self.id != trip.authorId) {
      add {
        FavoriteButton(trip.contentId, self, style { fontSize(2.cssRem) })
      }
    }
    add {
      LikeButton(trip.contentId, self, style { fontSize(2.cssRem) })
    }
    if (!short && self.id == trip.authorId) {
      add {
        Link(pages.editTrip.path(trip), modifier { classes("edit", "secondary") }) {
          Text(messages.edit())
        }
      }
    }
  }
  if (short) {
    add {
      Link(pages.trip.path(trip, false)) {
        GoogleIcon(Icons.ArrowRightAlt)
      }
    }
  }
}

@Composable
fun WayPoint(wayPoint: WayPoint, short: Boolean = false) = Row(modifier { classes("waypoint") }) {
  Row(modifier { classes("name") }) {
    Text(wayPoint.name)
  }
  wayPoint.location?.displayName?.let {
    Row(modifier { classes("location") }) {
      GoogleIcon(Icons.LocationOn)
      Text(it)
    }
  }
  if (wayPoint.since != null && wayPoint.until != null) {
    Row(modifier { classes("period") }) {
      GoogleIcon(Icons.CalendarMonth)
      val locale = rememberLocale()
      val formatter = remember(locale) { Intl.DateTimeFormat(locale.languageTag) }
      Text("${formatter.format(wayPoint.since!!)} - ${formatter.format(wayPoint.until!!)}")
    }
  }
  if (!short) {
    ImageGallery(ImagesViewModel.remember(wayPoint.images))
    MarkdownViewer(wayPoint.description, expandable = false)
  }
}