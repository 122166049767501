package tripper.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.NoLiveLiterals
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import tripper.*
import tripper.navigation.Pages
import tripper.search.SearchInput

@NoLiveLiterals
@Composable
fun Header(
  pages: Pages = LocalDependencies.current.pages,
  messages: Messages = rememberMessages(),
) = Div({ classes("header") }) {
  Link(pages.home.path(), modifier { classes("logo") }) {
    Text(messages.tipTrip())
  }
  Spacer(style { flexGrow(1) })
  SearchInput()
  
  if (rememberIsLoggedIn()) {
    Spacer(style { width(1.cssRem) })
    Link(pages.followings.path(), modifier { classes("followings") }) {
      Text(messages.followings())
    }
    Spacer(style { width(1.cssRem) })
    Tooltip(tooltip = {
      Text(messages.createTrip())
    }) {
      Link(pages.createTrip.path()) {
        GoogleIcon(Icons.Add, modifier {
          classes("create-trip")
        })
      }
    }
  }
  Spacer(style { flexGrow(1) })
  LanguagePicker()
  Spacer(style { width(1.cssRem) })
  AuthPanel()
}