package tripper.components

import androidx.compose.runtime.Composable
import tripper.Modifier
import tripper.emptyModifier
import tripper.modifier
import tripper.plus

@Composable
fun GoogleIcon(ligature: String, modifier: Modifier = emptyModifier(), hint: String? = null) {
  Column(modifier {
    hint?.let { title(it) }
    classes("material-symbols-outlined", "icon")
  } + modifier) {
    Text(ligature)
  }
}

object Icons {
  const val Star = "star"
  const val Login = "login"
  const val Add = "add"
  const val Language = "language"
  const val ExpandMore = "expand_more"
  const val ExpandLess = "expand_less"
  const val Favorite = "favorite"
  const val Search = "search"
  const val LocationOn = "location_on"
  const val CalendarMonth = "calendar_month"
  const val ArrowRightAlt = "arrow_right_alt"
  const val ArrowLeftAlt = "arrow_left_alt"
  const val Comment = "comment"
  const val Delete = "delete"
  const val Close = "close"
  const val NavigateBefore = "navigate_before"
  const val NavigateNext = "navigate_next"
}