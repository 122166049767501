package tripper

interface Storage {
  operator fun get(key: Key): String?
  
  operator fun set(key: Key, value: String)
  
  fun remove(key: Key)

  enum class Key { ACCESS_TOKEN }
}