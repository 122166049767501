package tripper

import androidx.compose.ui.text.intl.Locale
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import tripper.coroutines.mapState
import tripper.dto.Language
import tripper.dto.Language.EN

open class LocaleViewModel(locale: Locale = Locale.current) {
  private val localeInternal = MutableStateFlow(tripper.dto.Locale(locale.resolveLanguage(), locale.region.ifEmpty { null }))
  val locale = localeInternal.asStateFlow()
  val messages = localeInternal.mapState { Messages(it.language) }
  
  open fun setLanguage(language: Language) {
    localeInternal.update { it.copy(language = language) }
  }
  
  companion object {
    fun Locale.resolveLanguage(): Language {
      return Language.entries.find { it.code == language } ?: EN
    }
  }
}