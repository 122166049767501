package tripper.likes

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import tripper.comments.ContentId

class LikeService(private val client: HttpClient) {
  suspend fun like(contentId: ContentId): Like? = client.get("likes?contentId=$contentId").body<List<Like>>().firstOrNull()
  
  suspend fun save(like: Like): Like {
    val id = client.post("likes") { setBody(like) }.body<String>()
    return like.copy(rawId = id)
  }
  
  suspend fun delete(like: Like) {
    client.delete("likes/${like.id}")
  }
}