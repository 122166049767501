package tripper.auth

import androidx.compose.runtime.NoLiveLiterals
import tripper.auth.AuthAction.*
import tripper.auth.dto.VkAuth

@NoLiveLiterals
open class AuthService(private val authClient: AuthClient) {
  
  open suspend fun reduce(action: AuthAction) {
    when (action) {
      Logout -> logout()
      is LoginVk -> loginVk(action.auth)
      is LoginGoogle -> loginGoogle(action.token)
    }
  }

  private suspend fun loginVk(auth: VkAuth) {
    authClient.loginVk(auth)
  }
  
  private suspend fun loginGoogle(token: String) {
    authClient.loginGoogle(token)
  }
  
  private suspend fun logout() {
    authClient.logout()
  }
}