package tripper.comments

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import tripper.domain.Pagination

class CommentService(private val client: HttpClient) {

  suspend fun save(comment: Comment) {
    client.post("comments") {
      setBody(comment)
    }
  }

  suspend fun comments(contentId: ContentId, pagination: Pagination): List<Comment> {
    return client.get("comments"){
      parameter("contentId", contentId)
      parameter("lastDate", pagination.fields.lastDate)
      parameter("sort", pagination.sort)
      parameter("limit", pagination.limit)
    }.body()
  }
}