package tripper.users

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.update
import tripper.Loading
import tripper.Loading.None
import tripper.auth.AuthViewModel
import tripper.coroutines.SafeCoroutineScope

open class SelfViewModel(userService: UserService, authViewModel: AuthViewModel, scope: SafeCoroutineScope) {
  private val selfInternal = MutableStateFlow<Loading<User?>>(None)
  open val self = selfInternal.asStateFlow()

  init {
    scope.launch {
      authViewModel.isLoggedIn.collectLatest { isLoggedIn ->
        selfInternal.update { Loading.Finished(if (isLoggedIn) userService.self() else null) }
      }
    }
  }
}