package tripper.dadata

data class DadataCity(
  val fiasId: String,
  val name: String,
  val latitude: Double,
  val longitude: Double,
  val zoom: Int?,
)

val MOSKOW = DadataCity(
  fiasId = "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
  name = "Москва",
  latitude = 55.7540471,
  longitude = 37.620405,
  zoom = 12,
)