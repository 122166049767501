package tripper.search

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import tripper.domain.Pagination

class SearchService(private val client: HttpClient) {
  suspend fun search(text: String, pagination: Pagination): List<SearchResult> {
    return client.get("search?q=$text&page=${pagination.fields.page}&sort=${pagination.sort}&limit=${pagination.limit}").body()
  }
}