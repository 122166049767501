package tripper.lib.js

import kotlinx.datetime.*
import kotlinx.datetime.TimeZone.Companion.UTC
import kotlin.js.Date

external object Intl {
  class DateTimeFormat(languageTag: String, options: DateTimeFormatOptions = definedExternally) {
    fun format(date: Date): String
  }

  class RelativeTimeFormat(languageTag: String, options: dynamic = definedExternally) {
    fun format(number: Number, unit: String): String
  }
}

fun Intl.DateTimeFormat.format(date: Instant): String {
  return format(date.toJSDate())
}

fun Intl.DateTimeFormat.format(date: LocalDateTime): String {
  return format(date.toInstant(UTC))
}

external interface DateTimeFormatOptions {
  var dateStyle: String
  var timeStyle: String
}

fun Intl.RelativeTimeFormat.format(period: DateTimePeriod): String = when {
  period.years != 0 -> format(-period.years, "year")
  period.months != 0 -> format(-period.months, "month")
  period.days / 7 != 0 -> format(-period.days / 7, "week")
  period.days != 0 -> format(-period.days, "day")
  period.hours != 0 -> format(-period.hours, "hour")
  period.minutes != 0 -> format(-period.minutes, "minute")
  period.seconds != 0 -> format(-period.seconds, "second")
  else -> format(-0.0, "second")
}