package tripper.trips

import androidx.compose.runtime.*
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.minus
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList
import tripper.*
import tripper.components.*
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.domain.WayPoint
import tripper.lib.js.IntersectionObserver

@Composable
fun TripPage(label: String? = null, card: @Composable () -> Unit, comments: @Composable () -> Unit = {}) = Column(
  modifier { classes("trip-page") }) {
  Section(modifier { classes("trip") }, label) {
    card()
  }
  comments()
}

@Composable
fun TripCard(
  trip: Trip,
  map: @Composable TripCardScope.() -> Unit,
  header: @Composable () -> Unit,
  summary: @Composable () -> Unit,
  route: @Composable RouteScope.() -> Unit,
  controls: List<@Composable TripCardScope.() -> Unit>,
  messages: Messages = rememberMessages(),
) = Card(modifier { classes("trip-card") }) {
  val tripCardScope = remember { TripCardScope() }
  tripCardScope.map()
  Spacer(style { width(.5.cssRem) })
  Column(modifier { classes("content") }) {
    Row(modifier { classes("header") }) {
      header()
    }
    Row(modifier { classes("body") }) {
      summary()
      Row(modifier { classes("counters") }) {
        val start = trip.wayPoints.firstOrNull { it.since != null }?.since?.date
        val end = trip.wayPoints.lastOrNull { it.until != null }?.until?.date
        val period = if (start != null && end != null) end - start else DatePeriod()
        
        Text("${messages.period(period)} ${messages.places(trip.wayPoints.size)}")
      }

      val scrollAnchor = rememberScrollAnchor()
      RouteScope(scrollAnchor, tripCardScope).route()
    }
    Spacer(style { flexGrow(1) })

    if (controls.isNotEmpty()) {
      Divider(style {
        width(95.percent)
        alignSelf(AlignSelf.Center)
      })
      Controls(controls.map { { tripCardScope.it() } })
    }
  }
}

class RouteScope(val scrollAnchor: ScrollAnchor, private val tripCardScope: TripCardScope) {
  var focusedPointIndex 
    get() = tripCardScope.focusedPointIndex
    set(value) { tripCardScope.focusedPointIndex = value }
  
  var scrollToPointIndex
    get() = tripCardScope.scrollToPointIndex
    set(value) { tripCardScope.scrollToPointIndex = value }
}

class TripCardScope {
  var focusedPointIndex by mutableStateOf(0)
  var scrollToPointIndex by mutableStateOf<Int?>(null, neverEqualPolicy())
}

@Composable
fun Controls(items: List<@Composable () -> Unit>) {
  Row(modifier { classes("controls") }) {
    items.forEach { it() }
  }
}

@Composable
fun RouteScope.Route(
  wayPoints: List<WayPoint>,
  short: Boolean = false,
  scope: SafeCoroutineScope = rememberCoroutineScope(),
  wayPoint: @Composable (Int, WayPoint) -> Unit,
) {
  Ul({ classes("route") }) {
    var scrolling by remember { mutableStateOf(false) }
    var intersectionObserver by remember { mutableStateOf<IntersectionObserver?>(null) }
    wayPoints.forEachIndexed { index, wayPoint ->
      val scrollHandler = remember { ScrollHandler(scrollAnchor) }
      Li({ 
        classes("point")
        scrollHandler(scrollHandler)
      }) {
        wayPoint(index, wayPoint)
        DisposableEffect(intersectionObserver != null) {
          intersectionObserver?.observe(scopeElement)
          onDispose { intersectionObserver?.unobserve(scopeElement) }
        }
      }
      if (index == scrollToPointIndex) {
        scope.launch {
          scrolling = true
          try {
            focusedPointIndex = index
            scrollHandler.scrollToTop()
          } finally {
            scrolling = false
          }
        }
      }     
    }
    if (!short) {
      DisposableEffect(Unit) {
        intersectionObserver = IntersectionObserver({ entries, _ ->
          entries.forEach { entry ->
            if (entry.isIntersecting && !scrolling) {
              val index = scopeElement.children.asList().indexOf(entry.target)
              if (index >= 0) focusedPointIndex = index
            }
          }
        }, json {
          root = scrollAnchor.element
          rootMargin = "-30% 0% -70% 0%"
        })
        val lastPointHeight = (scopeElement.lastElementChild as HTMLElement?)?.offsetHeight ?: 0
        val scrollElementHeight = scrollAnchor.element.clientHeight
        scopeElement.style.paddingBottom = "${(scrollElementHeight - lastPointHeight).coerceAtLeast(0)}px"
        onDispose { intersectionObserver?.disconnect() }
      }
    }
  }
}