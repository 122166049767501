package tripper.components

import androidx.compose.runtime.*
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle.Companion.Block
import org.jetbrains.compose.web.css.DisplayStyle.Companion.None
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.w3c.dom.HTMLElement
import tripper.Modifier
import tripper.domain.Url
import tripper.emptyModifier
import tripper.users.User

@Composable
fun Avatar(user: User?, modifier: Modifier = emptyModifier()) {
  Avatar(user?.avatarUrl, user?.initials(), modifier)
}

@Composable
fun Avatar(url: Url?, initials: String?, modifier: Modifier = emptyModifier()) {
  fun AttrsScope<HTMLElement>.attributes() {
    classes("avatar")
    modifier.modify(this)
  }
  if (url != null || initials != null) {
    var imageLoaded by remember { mutableStateOf(false) }
    if ((!imageLoaded || url == null) && initials != null) {
      Div({
        classes("initials")
        style {
          backgroundColor(colors[initials.hashCode() % colors.size])
        }
        attributes()
      }) {
        Text(initials)
      }
    }
    if (url != null) {
      Img(url.value) {
        addEventListener("load") { imageLoaded = true }
        attributes()
        style {
          display(if (imageLoaded) Block else None)
        }
      }
    }
  } else {
    Img("/public/account.svg", attrs = {
      attributes()
    })
  }
}

fun User.initials(): String {
  return "${firstName.first()}${lastName.first()}"
}

private val colors = listOf(
  Color("#3FB8AF"),
  Color("#7FC7AF"),
  Color("#DAD8A7"),
  Color("#FF9E9D"),
  Color("#ff3d7f"),
)