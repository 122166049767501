package tripper.validation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.mapState
import tripper.coroutines.rememberCoroutineScope
import tripper.rememberMessages
import tripper.validation.ValidationResult.Companion.VALID
import kotlin.reflect.KProperty1

class FieldValidationViewModel(
  private val validators: Validators,
  private val scope: SafeCoroutineScope,
  private val paths: List<(ValidationResult) -> ValidationResult> = listOf { it },
  private val _validationResult: MutableStateFlow<ValidationResult> = MutableStateFlow(VALID),
) {
  val validationResults get() = _validationResult
    .mapState { result -> paths.map { it(result) } }
  
  fun setValidationResult(result: ValidationResult) {
    _validationResult.update { it.merge(result) }
  }

  fun reset() {
    _validationResult.update { it - validationResults.value }
  }

  fun validate(validate: Validators.() -> ValidationResult) {
    val result = validators.validate()
    setValidationResult(result)
    if (result.isInvalid) throw FieldValidationException(result)
  }
  
  @Composable
  fun ofPath(path: (ValidationResult) -> ValidationResult): FieldValidationViewModel {
    return ofPaths(path)
  }

  @Composable
  fun ofPaths(vararg paths: (ValidationResult) -> ValidationResult): FieldValidationViewModel {
    return remember { 
      val resultPaths = this.paths.flatMap { parentPath -> 
        paths.map { childPath -> { result: ValidationResult -> parentPath(result).let(childPath) } } 
      }
      FieldValidationViewModel(validators, scope, resultPaths, _validationResult) 
    }
  }

  @Composable
  inline fun <reified T: Any> ofField(field: KProperty1<T, *>): FieldValidationViewModel {
    return ofPath { it[field] }
  }
}

@Composable
fun rememberValidators() = Validators(rememberMessages())

@Composable
fun rememberFieldValidation(): FieldValidationViewModel {
  val validators = rememberValidators()
  val scope = rememberCoroutineScope()
  return remember { FieldValidationViewModel(validators, scope) }
}

data class FieldValidationException(val result: ValidationResult): Exception()