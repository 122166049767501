package tripper.components

import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.IntRect
import androidx.compose.ui.unit.IntSize
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.window.PopupPositionProvider
import kotlinx.browser.window
import org.jetbrains.compose.web.dom.ElementScope
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import tripper.Modifier
import tripper.modifier

@Composable
actual fun Popup(
  tag: String,
  alignment: Alignment,
  offset: IntOffset,
  positionProvider: PopupPositionProvider,
  layoutDirection: LayoutDirection,
  modifier: Modifier,
  onClick: () -> Unit,
  onClickOutside: () -> Unit,
  onClickContent: () -> Unit,
  content: @Composable () -> Unit,
) {
  if (!CssLoaded.current) return
  
  Column(modifier {
    classes("popup", tag)
    modifier.modify(this)
    onClick { 
      onClick()
      it.stopPropagation()
    }
  }) {
    content()
    
    eventHandlers(onClick, onClickContent, onClickOutside)

    var windowSize by remember { mutableStateOf(IntSize(window.innerWidth, window.innerHeight)) }
    DisposableEffect(Unit) {
      val updateSize: (Event) -> Unit = { windowSize = IntSize(window.innerWidth, window.innerHeight) }
      window.addEventListener("resize", updateSize)
      onDispose { window.removeEventListener("resize", updateSize) }
    }
    DisposableEffect(windowSize) {
      val parentBounds = scopeElement.parentElement?.getBoundingClientRect()?.let {
        IntRect(it.left.toInt(), it.top.toInt(), it.right.toInt(), it.bottom.toInt())  
      } ?: IntRect.Zero
      val popupContentSize = IntSize(scopeElement.offsetWidth, scopeElement.offsetHeight)
      val position = positionProvider.calculatePosition(parentBounds, windowSize, layoutDirection, popupContentSize)
      scopeElement.style.left = "${position.x}px"
      scopeElement.style.top = "${position.y}px"
      onDispose {}
    }
  } 
}

@Composable
private fun ElementScope<HTMLElement>.eventHandlers(onClick: () -> Unit, onClickContent: () -> Unit, onClickOutside: () -> Unit) {
  DisposableEffect(Unit) {
    val onEscape: (Event) -> Unit = { if ((it as KeyboardEvent).key == "Escape") onClick() }
    val onClickOutsideHandler: (Event) -> Unit = { onClickOutside() }
    val onClickContentHandler: (Event) -> Unit = {
      onClickContent()  
      it.stopPropagation() 
    }
    
    window.addEventListener("keydown", onEscape)
    window.addEventListener("click", onClickOutsideHandler)
    val children = scopeElement.children.asList()
    children.forEach { it.addEventListener("click", onClickContentHandler) }
    onDispose { 
      children.forEach { it.removeEventListener("click", onClickContentHandler) }
      window.removeEventListener("keydown", onEscape)
      window.removeEventListener("click", onClickOutsideHandler)
    }
  }
}