package tripper

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.plugins.logging.LogLevel.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.http.ContentType.*
import io.ktor.http.HttpHeaders.AcceptLanguage
import io.ktor.http.HttpHeaders.ContentType
import io.ktor.http.HttpHeaders.XRequestId
import io.ktor.http.HttpStatusCode.Companion.Forbidden
import io.ktor.http.HttpStatusCode.Companion.NotFound
import io.ktor.http.HttpStatusCode.Companion.RequestTimeout
import io.ktor.http.HttpStatusCode.Companion.TooManyRequests
import io.ktor.http.HttpStatusCode.Companion.Unauthorized
import io.ktor.http.HttpStatusCode.Companion.UnprocessableEntity
import io.ktor.serialization.kotlinx.json.*
import io.ktor.util.*
import kotlinx.coroutines.flow.StateFlow
import kotlinx.serialization.json.Json
import tripper.auth.AuthViewModel
import tripper.auth.dto.AccessGrant
import tripper.dto.Locale
import kotlin.random.Random

fun httpConfig(
  authViewModel: AuthViewModel,
  requestViewModel: RequestViewModel,
  locale: StateFlow<Locale>,
  random: Random = Random,
): HttpClientConfig<*>.() -> Unit = {
  defaultRequest {
    headers { 
      appendIfNameAbsent(ContentType, Application.Json.toString())
      append(AcceptLanguage, locale.value.languageTag)
      append(XRequestId, random.nextString(32))
    }
    url.path("/api/")
  }

  install(ContentNegotiation) {
    json(Json { jsonConfig() })
  }
  
  install(Logging) {
    level = NONE
  }
  
  install("CallListener") {
    plugin(HttpSend).intercept { context ->
      val request = Request(context.headers[XRequestId]!!)
      requestViewModel.requestStarted(request)
      try {
        execute(context)
      } finally {
        requestViewModel.requestFinished(request)
      }
    }
  }

  expectSuccess = true

  HttpResponseValidator {
    handleResponseExceptionWithRequest { cause, _ ->
      when (cause) {
        is ClientRequestException -> {
          throw when (cause.response.status) {
            Unauthorized -> UnauthorizedException(cause.response.bodyAsText().ifBlank { null })
            UnprocessableEntity -> ValidationException(cause.response.bodyAsText().ifBlank { null })
            Forbidden -> NoAccessException(cause.response.bodyAsText().ifBlank { null })
            NotFound -> NotFoundException(cause.response.bodyAsText().ifBlank { null })
            TooManyRequests -> LimitExceededException(cause.response.bodyAsText().ifBlank { null })
            RequestTimeout -> ClientException(cause.response.bodyAsText().ifBlank { null })
            else -> ClientException(cause.response.bodyAsText().ifBlank { null })
          }
        }

        is ServerResponseException -> throw InternalException(cause.response.bodyAsText().ifBlank { null })
      }
    }
  }

  install(Auth) {
    bearer {
      loadTokens { authViewModel.token.value?.let { BearerTokens(it.value, it.value) } }

      refreshTokens {
        try {
          val response = client.post("auth/tokens/refresh") { markAsRefreshTokenRequest() }
          val token = response.body<AccessGrant>().accessToken

          authViewModel.login(token)
          return@refreshTokens BearerTokens(token.value, token.value)
        } catch (e: Exception) {
          authViewModel.logout()
          throw e
        }
      }
    }
  }
}

private fun Random.nextString(length: Int, dictionary: String = "abcdefghijklmnopqrstuvwxyz0123456789+/=-"): String {
  return CharArray(length) { dictionary[nextInt(dictionary.length)] }.concatToString()
}