package tripper.comments

import androidx.compose.runtime.*
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone.Companion.UTC
import kotlinx.datetime.periodUntil
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Ul
import tripper.*
import tripper.components.*
import tripper.lib.js.IntersectionObserver
import tripper.lib.js.Intl
import tripper.lib.js.format
import tripper.navigation.Pages
import tripper.users.User
import tripper.users.ref
import tripper.validation.Restrictions.Comments
import tripper.validation.rememberFieldValidation

@Composable
fun Comments(
  contentId: ContentId,
  onLoaded: () -> Unit = {},
  modifier: Modifier = emptyModifier(),
  viewModel: CommentViewModel = CommentViewModel.create(contentId),
  pages: Pages = LocalDependencies.current.pages,
  clock: Clock = Clock.System,
  messages: Messages = rememberMessages(),
) = Section(modifier { classes("comments") } + modifier, messages.comments()) {
  val commentsLoading by viewModel.comments.collectAsState()
  val selfLoading = rememberSelfLoading()
  Card {
    val authors by viewModel.authors.collectAsState()
    val locale = rememberLocale()
    val relativeFormatter = remember(locale) { Intl.RelativeTimeFormat(locale.languageTag) }
    val dateFormatter = remember(locale) {
      Intl.DateTimeFormat(locale.languageTag, json {
        dateStyle = "short"
        timeStyle = "medium"
      })
    }

    commentsLoading.onLoaded { comments ->
      if (comments.isNotEmpty()) {
        Ul({ classes("list") }) {
          comments.forEach { comment ->
            Li({ classes("comment") }) {
              val author = authors[comment.authorId]
              Avatar(author, modifier {
                onClick { if (author != null) pages.profile.open(author) }
              })
              Column {
                Row(modifier {
                  classes("author")
                }) {
                  if (author != null) {
                    Link(pages.profile.path(author)) {
                      Text("@${author.ref}")
                    }
                  }

                  Row(modifier {
                    classes("date")
                    title(dateFormatter.format(comment.createdAt))
                  }) {
                    val createdAgo = comment.createdAt.periodUntil(clock.now(), UTC)
                    Text(relativeFormatter.format(createdAgo))
                  }
                }
                Spacer(style { height(2.px) })
                Row(modifier {
                  classes("text")
                }) {
                  Text(comment.text)
                }
              }
            }
          }
        }
        val hasMore by viewModel.hasMore.collectAsState()
        if (hasMore) {
          A(attrs = {
            classes("load-more")
            onClick { viewModel.loadMore() }
          }) {
            Text(messages.loadMoreComments())
          }
        }
      } else {
        Text(messages.noComments())
      }
    }

    AddComment(contentId, selfLoading, viewModel, messages, clock)
  }
  commentsLoading.onLoaded {
    selfLoading.onLoaded {
      onLoaded()
    }
  }
}

@Composable
private fun AddComment(
  contentId: ContentId,
  selfLoading: Loading<User?>,
  viewModel: CommentViewModel,
  messages: Messages,
  clock: Clock = Clock.System,
) {
  var pinned by remember { mutableStateOf(false) }
  val observer = remember {
    IntersectionObserver({ (entry), _ -> pinned = entry.intersectionRatio < 1 }, json { threshold = arrayOf(1f) })
  }

  Row(modifier { classes("add-comment") }) {
    DisposableEffect(Unit) {
      observer.observe(scopeElement)
      onDispose { observer.unobserve(scopeElement) }
    }
    selfLoading.untilLoaded(null)?.let { self ->
      Row(modifier {
        classes("surface")
        attr("pinned", pinned.toString())
      }) {
        Avatar(self)
        val commentText by viewModel.commentText.collectAsState()
        val fieldValidation = rememberFieldValidation()
        TextField(
          value = commentText,
          onValueChange = viewModel::setText,
          validation = fieldValidation,
          placeholder = messages.addComment(),
          singleLine = false,
          modifier = modifier { classes("text") },
          maxLength = Comments.maxLength,
        )
        Button({
          classes("send")
          onClick {
            val comment = Comment(contentId, self.id, commentText, createdAt = clock.now())
            fieldValidation.validate { comment(comment) }
            viewModel.save(comment)
          }
        }) {
          Text(messages.send())
        }
      }
    }
  }
}