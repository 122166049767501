package tripper.components

import androidx.compose.runtime.Composable
import tripper.Modifier
import tripper.emptyModifier
import tripper.tag

@Composable
fun Divider(modifier: Modifier = emptyModifier()) {
  Row(modifier.tag("divider")) { }
}