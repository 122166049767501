package tripper.users

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*

open class UserService(private val client: HttpClient) {
  
  open suspend fun self(): User = client.get("users/self").body()
  
  open suspend fun user(ref: UserRef): User = when(ref) {
    is UserRef.Id -> client.get("users/$ref").body() 
    is UserRef.Nickname -> client.get("users?nickname=$ref").body<List<User>>().first()
  }
  
  suspend fun users(ids: Set<UserRef.Id>): List<User> {
    if (ids.isEmpty()) return emptyList()
    return client.get("users") { ids.forEach { parameter("id", it) } }.body()
  }
  
  open suspend fun update(user: User) {
    client.patch("users") {
      setBody(user)
    }
  }
}