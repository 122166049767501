package tripper

import androidx.compose.runtime.NoLiveLiterals
import kotlinx.browser.document
import org.jetbrains.compose.web.renderComposable
import tripper.components.Application
import tripper.lib.Libs
import tripper.lib.vk.AuthParameters
import tripper.lib.vk.VK

@NoLiveLiterals
fun main() {
  document.addEventListener("DOMContentLoaded", {
    VK.init(object : AuthParameters {
      override val apiId = Config.vkAppId
    })
    Libs.init()
    renderComposable("root") {
      Application()
    }
  })
}