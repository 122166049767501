package tripper.lib.ymaps

import kotlinx.coroutines.await
import tripper.json
import kotlin.math.*

var domEvents = arrayOf(
  "click",
  "contextmenu",
  "dblclick",
  "mousedown",
  "mouseenter",
  "mouseleave",
  "mousemove",
  "mouseup",
  "multitouchend",
  "multitouchmove",
  "multitouchstart",
  "wheel",
)

suspend fun Arrow(coords: Array<Array<Double>>, options: dynamic): ymaps.Polyline {
  val createPolygon = ymaps.modules.require("overlay.Polygon").await()[0].unsafeCast<(ymaps.geometry.pixel.Polygon, dynamic, dynamic) -> ymaps.overlay.Polygon>()
  val overlayOptions = json<dynamic> {
    lineStringOverlay = { geometry: IGeometry, data: dynamic, options: dynamic ->
      ArrowOverlay(geometry, data, options, createPolygon)
    }
  }
  return ymaps.Polyline(
    coords,
    null,
    js("Object.assign(options, overlayOptions)"),
  )
}

/*
 * @param {geometry.pixel.Polyline} pixelGeometry Пиксельная геометрия линии.
 * @param {Object} data Данные оверлея.
 * @param {Object} options Опции оверлея.
 */

class ArrowOverlay(
  private var geometry: IGeometry?,
  private var data: dynamic,
  options: dynamic,
  private val createPolygon: (ymaps.geometry.pixel.Polygon, dynamic, dynamic) -> ymaps.overlay.Polygon,
): IOverlay {
  // Поля .events и .options обязательные для IOverlay.
  override val events = ymaps.event.Manager()
  override val options = ymaps.option.Manager(options)
  private var map: ymaps.Map? = null
  private var overlay: IOverlay? = null
  
  override fun getData() = data

  override fun setData(data: dynamic) {
    if (this.data != data) {
      val oldData = this.data
      this.data = data;
      events.fire("datachange", json<dynamic> {
          this.oldData = oldData
          newData = data
      })
    }
  }

  override fun getMap() = map
  
  override fun setMap(map: ymaps.Map?) {
    if (this.map != map) {
      val oldMap = this.map
      if (map == null) {
        onRemoveFromMap()
      }
      this.map = map;
      if (map != null) {
        onAddToMap()
      }
      events.fire("mapchange", json<dynamic> {
          this.oldMap = oldMap
          newMap = map
      })
    }
  }

  override fun setGeometry(geometry: IGeometry?) {
    if (this.geometry != geometry) {
      val oldGeometry = geometry
      this.geometry = geometry
      if (getMap() != null && geometry != null) {
        rebuild()
      }
      events.fire("geometrychange", json<dynamic> {
          this.oldGeometry = oldGeometry
          newGeometry = geometry
      })
    }
  }

  override fun getGeometry() = geometry

  override fun getShape() = null

  override fun isEmpty() = false

  private fun rebuild() {
    onRemoveFromMap()
    onAddToMap()
  }

  private fun onAddToMap() {
    // Военная хитрость - чтобы в прозрачной ломаной хорошо отрисовывались самопересечения,
    // мы рисуем вместо линии многоугольник.
    // Каждый контур многоугольника будет отвечать за часть линии.
    val createPolygon = createPolygon
    val geometry = ymaps.geometry.pixel.Polygon(createArrowContours(), "nonZero")
//    overlay = createPolygon(ymaps.geometry.pixel.Polygon(createArrowContours()))
    overlay = js("new createPolygon(geometry, null, { fill: true, fillColor: '0066ff99', fillMethod: 'stretch' })")
    startOverlayListening()
    // Эта строчка свяжет два менеджера опций.
    // Опции, заданные в родительском менеджере,
    // будут распространяться и на дочерний.
    overlay!!.options.setParent(this.options)
    overlay!!.setMap(this.getMap())
  }

  private fun onRemoveFromMap() {
    overlay!!.setMap(null)
    overlay!!.options.setParent(null)
    stopOverlayListening()
  }

  private fun startOverlayListening() {
    overlay!!.events.add(domEvents, ::onDomEvent, this)
  }

  private fun stopOverlayListening() {
    overlay!!.events.remove(domEvents, ::onDomEvent, this)
  }

  private fun onDomEvent(event: ymaps.Event) {
    // Мы слушаем события от дочернего служебного оверлея
    // и прокидываем их на внешнем классе.
    // Это делается для того, чтобы в событии было корректно определено
    // поле target.
    events.fire(event.get("type"), ymaps.Event(json {
      target = this@ArrowOverlay
      // Свяжем исходное событие с текущим, чтобы все поля данных дочернего события
      // были доступны в производном событии.
    }, event))
  }

  private fun createArrowContours(): Array<Array<Array<Double>>> {
    val contours = ArrayList<Array<Array<Double>>>()
    val mainLineCoordinates = getGeometry().unsafeCast<ymaps.geometry.LineString>().getCoordinates()
    val arrowLength = calculateArrowLength(
      mainLineCoordinates,
      options.get("arrowMinLength", 3),
      options.get("arrowMaxLength", 20)
    )
    contours += getContourFromLineCoordinates(mainLineCoordinates)
    // Будем рисовать стрелку только если длина линии не меньше длины стрелки.
    if (arrowLength > 0.0) {
      // Создадим еще 2 контура для стрелочек.
      val lastTwoCoordinates = arrayOf(
        mainLineCoordinates[mainLineCoordinates.size - 2],
        mainLineCoordinates[mainLineCoordinates.size - 1]
      )
      // Для удобства расчетов повернем стрелку так, чтобы она была направлена вдоль оси y,
      // а потом развернем результаты обратно.
      val rotationAngle = getRotationAngle(lastTwoCoordinates[0], lastTwoCoordinates[1])
      val rotatedCoordinates = rotate(lastTwoCoordinates, rotationAngle)

      val arrowAngle = options.get("arrowAngle", 20) / 180.0 * PI
      val arrowBeginningCoordinates = getArrowsBeginningCoordinates(
        rotatedCoordinates,
        arrowLength,
        arrowAngle
      )
      val firstArrowCoordinates = rotate(
        arrayOf(
          arrowBeginningCoordinates[0],
          rotatedCoordinates[1]
        ), -rotationAngle
      )
      val secondArrowCoordinates = rotate(
        arrayOf(
          arrowBeginningCoordinates[1],
          rotatedCoordinates[1]
        ), -rotationAngle
      )

//      contours += firstArrowCoordinates.reversedArray() + secondArrowCoordinates
      contours += getContourFromLineCoordinates(firstArrowCoordinates)
      contours += getContourFromLineCoordinates(secondArrowCoordinates)
    }
    return contours.toTypedArray()
  }
}

private fun getArrowsBeginningCoordinates(coordinates: Array<Array<Double>>, arrowLength: Double, arrowAngle: Double): Array<Array<Double>> {
  val p1 = coordinates[0]
  val p2 = coordinates[1]
  val dx = arrowLength * sin(arrowAngle)
  val y = p2[1] - arrowLength * cos(arrowAngle)
  return arrayOf(arrayOf(p1[0] - dx, y), arrayOf(p1[0] + dx, y))
}

private fun rotate(coordinates: Array<Array<Double>>, angle: Double): Array<Array<Double>> {
  return coordinates.map { 
    val x = it[0]
    val y = it[1]
    arrayOf(
      x * cos(angle) - y * sin(angle),
      x * sin(angle) + y * cos(angle),
    )
  }.toTypedArray()
}

private fun getRotationAngle(p1: Array<Double>, p2: Array<Double>): Double {
  return PI / 2 - atan2(p2[1] - p1[1], p2[0] - p1[0])
}

private fun getContourFromLineCoordinates(coords: Array<Array<Double>>): Array<Array<Double>> {
  return coords + coords.dropLast(1).asReversed()
}

private fun calculateArrowLength(coords: Array<Array<Double>>, minLength: Int, maxLength: Int): Double {
  var linePixelLength = 0.0
  for (i in 1 until coords.size) {
    linePixelLength += getVectorLength(
      coords[i][0] - coords[i - 1][0],
      coords[i][1] - coords[i - 1][1]
    )
    if (linePixelLength / 3 > maxLength) return maxLength.toDouble()
  }
  val finalArrowLength = linePixelLength / 3
  return if (finalArrowLength < minLength) 0.0 else finalArrowLength
}

fun getVectorLength(x: Double, y: Double): Double = sqrt(x * x + y * y)
