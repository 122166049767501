package tripper.followings

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.update
import tripper.auth.AuthViewModel
import tripper.coroutines.SafeCoroutineScope
import tripper.users.User

class FollowingsViewModel(
  private val service: FollowingService,
  private val authViewModel: AuthViewModel,
  private val scope: SafeCoroutineScope,
) {
  private val _followings = MutableStateFlow<List<Following>?>(null)
  val followings = _followings.asStateFlow()
  
  init {
    scope.launch {
      authViewModel.isLoggedIn.collectLatest { isLoggedIn -> _followings.update { if (isLoggedIn) service.followings() else null } }
    }
  }
  
  fun follow(author: User, self: User) {
    scope.launch {
      val following = service.save(Following(author.id, self.id))
      _followings.update { it.orEmpty() + following }
    }
  }
  
  fun unfollow(following: Following) {
    scope.launch {
      service.delete(following)
      _followings.update { it?.let { it - following } }
    }
  } 
}