package tripper.lib.vk

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.datetime.Instant
import tripper.LocalDependencies
import tripper.auth.AuthAction.LoginVk
import tripper.auth.AuthViewModel
import tripper.auth.dto.Provider.Vk
import tripper.auth.dto.VkAuth
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.users.FullUser
import tripper.users.UserRef.Nickname

open class VkAuth(private val auth: LibVkAuth, private val scope: SafeCoroutineScope, private val viewModel: AuthViewModel) {
  
  open fun login() {
    auth.login { data ->
      if (data.session != null && data.status == "connected") {
        with(data.session!!) {
          scope.launch {
            viewModel.onAction(
              LoginVk(
                VkAuth(
                  user = FullUser(Vk(user.id), null, null, user.first_name, user.last_name, Nickname(user.domain)),
                  signature = sig,
                  expire = Instant.fromEpochSeconds(expire, 0),
                  accessToken = sid,
                  secret = secret
                )
              )
            )
          }
        }
      }
      // TODO: If not authenticated 
    }
  }
  
  companion object {
    @Composable
    fun create(): tripper.lib.vk.VkAuth {
      val auth = VkAuth(VK.Auth, rememberCoroutineScope(), LocalDependencies.current.authViewModel)
      return remember { auth }
    } 
  }
}