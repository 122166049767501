package tripper.trips

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import tripper.Loading
import tripper.Loading.None
import tripper.LocalDependencies
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.domain.Fields
import tripper.domain.Pagination
import tripper.domain.Sort.RECENT
import tripper.load
import tripper.untilLoaded
import tripper.users.UserRef

open class TripsViewModel(
  private val favorites: Boolean?,
  private val followings: Boolean?,
  private val authorId: UserRef.Id?,
  private val tripService: TripService,
  private val scope: SafeCoroutineScope,
) {
  private val tripsInternal = MutableStateFlow<Loading<List<Trip>>>(None)
  private val hasMore = MutableStateFlow(true)
  open val trips = tripsInternal.asStateFlow()
  
  open fun loadMore() {
    if (!hasMore.value) return
    scope.launch {
      tripsInternal.load {
        val trips = it.untilLoaded(emptyList())
        val newTrips = tripService.trips(
          pagination(trips),
          followings = followings,
          favorites = favorites,
          authorId = authorId,
        )
        hasMore.update { newTrips.size >= tripsPerPage }
        trips + newTrips 
      }
    }
  }
  
  private fun pagination(trips: List<Trip>): Pagination {
    val lastTrip = trips.lastOrNull()
    return Pagination(Fields(lastDate = lastTrip?.createdAt), sort = RECENT, _limit = tripsPerPage)
  }
  
  companion object {
    const val tripsPerPage = 20 
    
    @Composable
    fun remember(favorites: Boolean? = null, followings: Boolean? = null, authorId: UserRef.Id? = null): TripsViewModel {
      val scope = rememberCoroutineScope()
      val tripService = LocalDependencies.current.tripService
      return remember(favorites, followings, authorId) { 
        TripsViewModel(favorites, followings, authorId, tripService, scope).also { it.loadMore() } 
      }
    }
  }
}