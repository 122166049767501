package tripper

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.remember
import kotlinx.browser.window
import kotlinx.coroutines.CompletableDeferred
import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.*
import tripper.components.RowScope
import tripper.coroutines.await
import kotlin.math.abs
import kotlin.time.Duration.Companion.seconds

class ScrollHandler(private val scrollAnchor: ScrollAnchor) {
  lateinit var element: HTMLElement
  
  suspend fun scrollToTop() {
    val deferred = CompletableDeferred<Unit>()
    val top = element.getBoundingClientRect().top - scrollAnchor.element.getBoundingClientRect().top
    if (abs(top) < 1) return
    if (window.asDynamic().onscrollend != undefined) {
      scrollAnchor.element.addEventListener("scrollend", { deferred.complete(Unit) }, json { once = true })
      scrollAnchor.element.scrollBy(ScrollToOptions(top = top, behavior = ScrollBehavior.SMOOTH))
    } else {
      scrollAnchor.element.scrollBy(ScrollToOptions(top = top, behavior = ScrollBehavior.INSTANT))
      deferred.complete(Unit)
    }
    deferred.await(1.seconds)
  }
}

class ScrollAnchor {
  lateinit var element: HTMLElement
}

@Composable
fun RowScope.rememberScrollAnchor(): ScrollAnchor {
  val anchor = remember { ScrollAnchor() }
  DisposableEffect(Unit) {
    anchor.element = scopeElement
    onDispose {  }
  }  
  return anchor
}

fun AttrsScope<HTMLElement>.scrollHandler(handler: ScrollHandler) {
  ref {
    handler.element = it
    onDispose {  }
  }
}