package tripper.geocoding

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import tripper.domain.Coords
import tripper.domain.Location

class GeocodingService(private val client: HttpClient) {
  
  suspend fun geocode(coords: Coords): Location {
    return client.get("geocode?lat=${coords.latitude}&lon=${coords.longitude}").body()
  }
}