package tripper.navigation

import tripper.Messages
import tripper.trips.Trip
import tripper.users.User

class Pages(val navigator: NavigatorViewModel) {
  private val pages = ArrayList<Page>()
  
  val home = Page0({ home() }, Regex("^$")) { "/" }
  val createTrip = Page0({ createTrip() }, Regex("^/trips/create$"), protected = true) { "/trips/create" }
  val editTrip = Page1({ editTrip() }, Regex("^/trips/${pathArg("id")}/edit$"), protected = true) { trip: Trip -> "/trips/${trip.id}/edit" }
  val trips = Page0({ trips() }, Regex("^/trips$")) { "/trips" }
  val trip = Page2({ trip() }, Regex("^/trips/${pathArg("id")}$")) { trip: Trip, comments: Boolean -> "/trips/${trip.id}" + if (comments) "#comments" else "" }
  val search = Page1({ search() }, Regex("^/search$")) { query: String -> "/search?q=$query" }
  val favorites = Page0({ favorites() }, Regex("^/favorites$"), protected = true) { "/favorites" }
  val followings = Page0({ followings() }, Regex("^/followings$"), protected = true) { "/followings" }
  val profile = Page1({ profile() }, Regex("^(/users/${pathArg("id")}|/${pathArg("nickname")})$")) { user: User -> user.nickname?.let { "/$it" } ?: "/users/${user.id}" }
  
  fun find(path: String): Pair<Page?, (String) -> String?> {
    val sanitizedPath = path.trimEnd('/')
    pages.forEach { page ->
      val result = page.regex.find(sanitizedPath)
      if (result != null) return page to {
        try {
          (result.groups as MatchNamedGroupCollection)[it]?.value
        } catch (e: IllegalArgumentException) {
          null
        }  
      }
    }
    return null to { null }
  }
  
  private fun pathArg(name: String) = "(?<$name>[^/]+)"

  abstract inner class Page(val title: Messages.() -> String, val regex: Regex, val protected: Boolean = false) {
    init {
      pages += this
    }
  }

  inner class Page0(title: Messages.() -> String, regex: Regex, protected: Boolean = false, val path: () -> String): Page(title, regex, protected) {
    fun open() {
      navigator.path(path())
    }

    fun inlinePath() {
      navigator.inlinePath(path())
    }
  }
 
  inner class Page1<T>(title: Messages.() -> String, regex: Regex, protected: Boolean = false, val path: (T) -> String): Page(title, regex, protected) {
    fun open(arg: T) {
      navigator.path(path(arg))
    }

    fun inlinePath(arg: T) {
      navigator.inlinePath(path(arg))
    }
  }

  inner class Page2<T1, T2>(title: Messages.() -> String, regex: Regex, protected: Boolean = false, val path: (T1, T2) -> String): Page(title, regex, protected) {
    fun open(arg1: T1, arg2: T2) {
      navigator.path(path(arg1, arg2))
    }

    fun inlinePath(arg1: T1, arg2: T2) {
      navigator.inlinePath(path(arg1, arg2))
    }
  }
}