package tripper.components

import androidx.compose.runtime.*
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Ul
import org.w3c.dom.HTMLElement
import tripper.lib.js.IntersectionObserver
import tripper.lib.js.IntersectionObserverEntry

//todo make infinite scroll virtualized
@Composable
fun <T> InfiniteScrollList(
  items: List<T>,
  key: (T) -> Any,
  loadMore: () -> Unit,
  loadMoreIndex: Int = (items.size - 1).coerceAtLeast(0),
  observer: IntersectionObserver = remember { IntersectionObserver(intersectionHandler(loadMore)) },
  itemContent: @Composable (T) -> Unit,
) = Ul({ classes("infinite-list") }) {
  DisposableEffect(Unit) {
    onDispose { observer.disconnect() }
  }
  val observedItems = remember { mutableStateListOf<HTMLElement>() }
  remember(items.map(key)) {
    observedItems.forEach { observer.unobserve(it) }
    observedItems.clear()
  }

  items.forEachIndexed { index, item ->
    key(key(item)) {
      Li({ classes("item") }) {
        itemContent(item)
        if (index >= loadMoreIndex) {
          DisposableEffect(Unit) {
            observer.observe(scopeElement)
            observedItems += scopeElement
            onDispose { 
              observer.unobserve(scopeElement)
              observedItems -= scopeElement
            }
          }
        }
      }
    }
  }
}

fun intersectionHandler(loadMore: () -> Unit) = { entries: Array<IntersectionObserverEntry>, _: IntersectionObserver ->
  entries.forEach { entry ->
    if (entry.isIntersecting) {
      loadMore()
    }
  }
}