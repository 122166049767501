package tripper.components

import androidx.compose.runtime.Composable
import tripper.Modifier
import tripper.emptyModifier
import tripper.tag

@Composable
fun Card(modifier: Modifier = emptyModifier(), content: @Composable RowScope.() -> Unit) = Row(modifier.tag("card")) {
  content()
}