package tripper.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import kotlinx.browser.window
import kotlinx.dom.hasClass
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import tripper.Modifier
import tripper.emptyModifier
import tripper.modifier
import tripper.plus

@Composable
fun Overlay(
  modifier: Modifier = emptyModifier(),
  onClick: () -> Unit = {},
  onDismissRequest: () -> Unit = {},
  content: @Composable () -> Unit,
) {
  Column(modifier { 
    classes("overlay")
    onClick { if ((it.target as? HTMLElement)?.hasClass("overlay") == true) onClick() }
    onKeyDown { if (it.key == "Escape") onDismissRequest() }
  } + modifier) {
    content()
    
    DisposableEffect(Unit) {
      val onEscape = { event: Event -> if ((event as KeyboardEvent).key == "Escape") onDismissRequest() }
      window.addEventListener("keydown", onEscape)
      onDispose { window.removeEventListener("keydown", onEscape) }
    }
  }
}