package tripper.search

import androidx.compose.runtime.*
import org.jetbrains.compose.web.dom.Div
import tripper.*
import tripper.components.GoogleIcon
import tripper.components.Icons
import tripper.components.TextField
import tripper.navigation.Pages
import tripper.validation.Restrictions.SearchQuery
import tripper.validation.rememberFieldValidation

@Composable
fun SearchInput(
  viewModel: SearchViewModel = LocalDependencies.current.searchViewModel,
  pages: Pages = LocalDependencies.current.pages,
  messages: Messages = rememberMessages(),
) = Div({ classes("search-input") }) {
  var query by remember { mutableStateOf("") }
  val results by viewModel.results.collectAsState()
  val validation = rememberFieldValidation()
  
  fun search() {
    validation.validate { searchQuery(query) }
    viewModel.search(query)
  }
  
  remember { 
    viewModel.onInvokeSearch {
      query = it
      search() 
    } 
  }
  
  GoogleIcon(Icons.Search, modifier {
    classes("icon")
    onClick {
      search()
    }
  })
  TextField(
    query,
    onValueChange = { query = it },
    validation = validation,
    maxLength = SearchQuery.maxLength,
    placeholder = messages.search(),
    counter = false,
    modifier = modifier {
      classes("input")
      onKeyDown { if (it.key == "Enter") search() }
    },
  )
  
  if (results is Loading.Finished) pages.search.open(query)
}