package tripper.files

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import io.ktor.http.*
import io.ktor.http.ContentType.Image.JPEG
import kotlinx.browser.document
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.Image
import org.w3c.dom.url.URL
import org.w3c.files.File
import org.w3c.files.FilePropertyBag
import tripper.domain.Url
import tripper.json
import tripper.rememberMessages
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

actual class ImageConverter(private val fileReader: FileReaderAdapter) {
  actual fun compress(file: FileData): ImageConvertProgress {
    return object : ImageConvertProgress {
      private var onPreLoad: (Url) -> Unit = {}
      private var onCompressed: (Url) -> Unit = {}
      
      override fun onPreLoad(block: (Url) -> Unit): ImageConvertProgress {
        onPreLoad = block
        return this
      }

      override fun onCompressed(block: (Url) -> Unit): ImageConvertProgress {
        onCompressed = block
        return this
      }

      override suspend fun result(): FileData {
        val fileUrl = Url(URL.createObjectURL(File(arrayOf(file.file), file.name, FilePropertyBag(type = file.type.toString()))))
        onPreLoad(fileUrl)
        
        val fileToUpload = if (file.type.isUncompressedImage()) {
          val compressedImage = convertToWebp(fileUrl, file.name)
          onCompressed(Url(URL.createObjectURL(compressedImage)))
          FileData(fileReader.read(compressedImage), compressedImage.name, ContentType.parse(compressedImage.type))
        } else file
        
        return fileToUpload
      }
    }
  }

  private suspend fun convertToWebp(fileUrl: Url, fileName: String) = suspendCoroutine { cont ->
    val image = Image()
    image.addEventListener("load", {
      URL.revokeObjectURL(fileUrl.value)
      val canvas = document.createElement("canvas").unsafeCast<HTMLCanvasElement>()
      canvas.width = image.naturalWidth
      canvas.height = image.naturalHeight
      canvas.getContext("2d").unsafeCast<CanvasRenderingContext2D>().drawImage(image, 0.0, 0.0)
      canvas.toBlob({ blob ->
        val compressedImage = File(arrayOf(blob), "${fileName.substringBeforeLast('.')}.webp", FilePropertyBag(type = blob?.type))
        cont.resume(compressedImage)
      }, "image/webp")
    }, json { once = true })

    image.src = fileUrl.value
  }

  private fun ContentType.isUncompressedImage(): Boolean {
    return match(ContentType.Image.Any) && !match(JPEG) && !match(ContentType.Image.WEBP)
  }
}

@Composable
actual fun rememberImageConverter(): ImageConverter {
  val messages = rememberMessages()
  val fileReader = remember { FileReaderAdapter(10 * 1024 * 1024, messages) }
  return remember { ImageConverter(fileReader) }
}