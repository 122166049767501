package tripper.navigation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import io.ktor.http.*
import tripper.*
import tripper.components.NotFound
import tripper.components.PageTitle
import tripper.navigation.Pages.Page
import tripper.users.User

@Composable
fun Navigator(
  navigatorViewModel: NavigatorViewModel = LocalDependencies.current.navigatorViewModel,
  pages: Pages = LocalDependencies.current.pages,
  messages: Messages = rememberMessages(),
  navigate: @Composable Pages.(Page, Arguments, User?) -> Unit
) {
  //todo consider subscribe on page instead of url
  val url by navigatorViewModel.url.collectAsState()
  val selfLoading = rememberSelfLoading()

  val (page, pathArgs) = remember(url.encodedPath) { pages.find(url.encodedPath) }
  val subtitle = page?.title ?: { pageNotFound() }
  PageTitle(messages.title(messages.subtitle()))
  if (page == null) {
    NotFound()
  } else {
    if (page.protected) selfLoading.onLoaded { 
      if (it != null) pages.navigate(page, Arguments(pathArgs, url), it)
      //todo Think how to notice user about required authentication
      else pages.home.open()
    }
    else pages.navigate(page, Arguments(pathArgs, url), null)
  }
}

class Arguments(private val path: (String) -> String?, private val url: Url) {
  operator fun get(name: String): String = optional(name) ?: throw InternalException("Argument $name not provided")
  
  fun optional(name: String): String? = url.parameters[name] ?: if (url.fragment == name) "true" else null ?: path(name)
  
  operator fun contains(name: String): Boolean = name in url.parameters || name == url.fragment || path(name) != null
}