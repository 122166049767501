package tripper.lib.ymaps

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.NoLiveLiterals
import androidx.compose.runtime.remember
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.await
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import org.jetbrains.compose.web.css.DisplayStyle.Companion.Block
import org.jetbrains.compose.web.css.display
import org.w3c.dom.HTMLElement
import tripper.*
import tripper.components.LazyColumn
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.domain.Coords
import tripper.domain.Location
import tripper.domain.WayPoint

@NoLiveLiterals
open class YandexMapHandler(
  private val scope: SafeCoroutineScope,
  private val editable: Boolean = false,
  private val onPointDragEnd: (Int, Coords) -> Unit = { _, _ -> },
) {
  private var addPointMode = false
  private val points = CompletableDeferred<ymaps.GeoObjectCollection<ymaps.Placemark>>()
  private val arrows = CompletableDeferred<ymaps.GeoObjectCollection<ymaps.Polyline>>()
  private val map = CompletableDeferred<ymaps.Map>()
  private val search = CompletableDeferred<ymaps.control.SearchControl>()
  private val lock = Mutex()

  open fun render(
    parent: HTMLElement,
    center: Coords,
    zoom: Int?,
    wayPoints: List<WayPoint>,
    scrollZoom: Boolean = true,
    onPointClick: (Int) -> Unit = {},
    onSearchResult: (Location) -> Unit = {},
  ) {
    scope.launch {
      ymap.await()

      val map = map.complete {
        //todo detect current user city
        ymaps.Map(parent, json {
          this.center = arrayOf(center.latitude, center.longitude)
          this.zoom = zoom
          controls = arrayOf("zoomControl", "rulerControl")
        }, json {
          maxZoom = 15
          yandexMapDisablePoiInteractivity = true
        })
      }
      map.fixInitialChangeCopyright()
      if (!scrollZoom) map.behaviors.disable("scrollZoom")
      val points = points.complete { ymaps.GeoObjectCollection(json { }) }
      val arrows = arrows.complete { ymaps.GeoObjectCollection(json { }) }
      points.events.add("drag") {
        val target = it.get<ymaps.Placemark>("target")
        val coords = target.geometry.getCoordinates()
        arrows.move(points.indexOf(target), coords)
      }
      points.events.add("dragend") {
        val target = it.get<ymaps.Placemark>("target")
        val coords = target.geometry.getCoordinates()
        onPointDragEnd(points.indexOf(target), Coords(coords[0], coords[1]))
      }
      points.events.add("click") {
        val target = it.get<ymaps.Placemark>("target")
        onPointClick(points.indexOf(target))
      }
      map.geoObjects.add(points)
      map.geoObjects.add(arrows)
      wayPoints.mapNotNull { it.location?.coords }.forEach { coords -> 
        points.addPoint(placemark(arrayOf(coords.latitude, coords.longitude))) 
      }
      
      if (editable) {
        val search = search.complete {
          ymaps.control.SearchControl(json {
            options = json {
              provider = "yandex#map"
              float = "right"
              noPlacemark = true
              noCentering = true
            }
          })
        }
        search.events.add("resultselect") { event ->
          scope.launch {
            val result = search.getResult(event.get("index")).await()
            val coords = result.geometry.getCoordinates()
            onSearchResult(Location(
              Coords(coords[0], coords[1]),
              result.getAddressLine(),
              result.getLocalities().first(),
              result.getCountry(),
            ))
          }
        }

        map.controls.add(search)
      }
    }
  }
  
  fun focusPoint(index: Int) {
    scope.launch {
      lock.withLock {
        points.await().toArray().forEachIndexed { pointIndex, point ->
          if (pointIndex == index) point.options.set("iconColor", Colors.FOCUSED)
          else point.options.set("iconColor", Colors.DEFAULT)
        }
      }
    }
  }
  
  fun removePoint(index: Int) {
    scope.launch {
      lock.withLock {
        val points = points.await()
        val arrows = arrows.await()
        points.get(index)?.let { points.remove(it) }

        val incoming = arrows.get(index - 1)
        val outgoing = arrows.get(index)
        if (incoming != null && outgoing != null) {
          val arrow = arrow(arrayOf(incoming.geometry.getCoordinates()[0], outgoing.geometry.getCoordinates()[1]))
          arrows.splice(index - 1, 2, arrow)
        } else {
          outgoing?.let { arrows.remove(it) }
          incoming?.let { arrows.remove(it) }
        }
      }
    }
  }
  
  fun clearSearch() {
    scope.launch {
      lock.withLock {
        search.await().clear()
      }
    }
  }
  
  fun addPointMode(onAddPoint: (Int, Coords) -> Unit) {
    if(addPointMode) return
    addPointMode = true
    scope.launch {
      lock.withLock {
        val map = map.await()
        val points = points.await()
        val search = search.await()
        val placemark = placemark(arrayOf(0.0, 0.0), disabled = true)
        map.geoObjects.add(placemark)
        val cursor = map.cursors.push("pointer")

        val cleanEventHandlers = ArrayList<() -> Unit>()
        val exitAddPointMode = {
          map.geoObjects.remove(placemark)
          cursor.remove()
          addPointMode = false
          cleanEventHandlers.forEach { it() }
        }

        val onMouseMove: (ymaps.Event) -> Unit = {
          val coords = it.get<Array<Double>>("coords")
          placemark.geometry.setCoordinates(coords)
          it.stopPropagation()
        }
        val passEvents: (ymaps.Event) -> Unit = {
          map.events.fire(it.get("type"), it)
        }
        val onSearchResult: (ymaps.Event) -> Unit = {
          exitAddPointMode()
        }
        val onMapClick: (ymaps.Event) -> Unit = {
          exitAddPointMode()

          val coords = try {
            it.get<ymaps.Placemark>("target").geometry.getCoordinates()
          } catch (e: Throwable) {
            it.get("coords")
          }
          onAddPoint(points.getLength(), Coords(coords[0], coords[1]))
        }
        cleanEventHandlers.add { search.events.remove("resultselect", onSearchResult) }
        cleanEventHandlers.add { map.events.remove("click", onMapClick) }
        cleanEventHandlers.add { map.events.remove("mousemove", onMouseMove) }
        cleanEventHandlers.add { placemark.events.remove(domEvents, passEvents) }

        map.events.add("mousemove", onMouseMove)
        map.events.add("click", onMapClick)
        placemark.events.add(domEvents, passEvents)
        search.events.add("resultselect", onSearchResult)
      }
    }
  }

  private fun ymaps.Map.fixInitialChangeCopyright() {
    copyrights.events.add("change") {
      container.fitToViewport()
    }
  }

  open fun setRoute(wayPoints: List<WayPoint>, focusedIndex: Int? = null, applyBounds: Boolean = true) {
    if (wayPoints.all { it.location == null }) return
    scope.launch {
      lock.withLock {
        val map = map.await()
        val points = points.await()
        val arrows = arrows.await()

        wayPoints.mapNotNull { it.location?.coords }.forEachIndexed { index, coords ->
          val point = points.get(index)
          val coordArray = arrayOf(coords.latitude, coords.longitude)
          if (point == null) {
            points.addPoint(placemark(coordArray, focused = index == focusedIndex))
          } else if (!point.geometry.getCoordinates().contentEquals(coordArray)) {
            point.geometry.setCoordinates(coordArray)
            arrows.move(index, coordArray)
          }
        }
        if (applyBounds) {
          points.getBounds()?.let {
            map.setBounds(it, json {
              checkZoomRange = true
              zoomMargin = 20
            })
          }
        }
      }
    }
  }

  open fun destroy() {
    scope.launch {
      lock.withLock {
        map.await().destroy()
      }
    }
  }

  fun panTo(coords: Coords) {
    scope.launch {
      lock.withLock {
        map.await().panTo(arrayOf(coords.latitude, coords.longitude))
      }
    }
  }

  private suspend fun ymaps.GeoObjectCollection<ymaps.Placemark>.addPoint(placemark: ymaps.Placemark) {
    if (getLength() > 0) {
      val prevCoords = get(getLength() - 1)!!.geometry.getCoordinates()
      val currentCoords = placemark.geometry.getCoordinates()
      val arrows = arrows.await()
      arrows.add(arrow(arrayOf(prevCoords, currentCoords)))
    }
    add(placemark)
  }

  private fun placemark(coords: Array<Double>, disabled: Boolean = false, focused: Boolean = false): ymaps.Placemark {
    return ymaps.Placemark(arrayOf(coords[0], coords[1]), json {}, json {
      preset = "islands#dotIcon"
      iconColor = when {
        disabled -> Colors.DISABLED
        focused -> Colors.FOCUSED
        else -> Colors.DEFAULT
      }
      draggable = editable
    })
  }

  private suspend fun arrow(coords: Array<Array<Double>>) = Arrow(coords, json {
    geodesic = true
    strokeWidth = 2
    arrowAngle = 15
    arrowMaxLength = 15
    strokeColor = Colors.DEFAULT
  })

  private fun ymaps.GeoObjectCollection<ymaps.Polyline>.move(pointIndex: Int, coords: Array<Double>) {
    val fromArrow = get(pointIndex - 1)?.geometry
    val toArrow = get(pointIndex)?.geometry
    fromArrow?.setCoordinates(arrayOf(fromArrow.getCoordinates()[0], coords))
    toArrow?.setCoordinates(arrayOf(coords, toArrow.getCoordinates()[1]))
  }

  companion object {
    fun init() = Unit
    private val ymap = ymaps.ready()
  }
  
  object Colors {
    val DISABLED = "#7DA6BD"
    val DEFAULT = "#4D7D99"
    val FOCUSED = "#FF9900"
  }
}

@Composable 
fun rememberYandexMapHandler(
  editable: Boolean = false,
  onPointDragEnd: (Int, Coords) -> Unit = { _, _ -> },
): YandexMapHandler {
  val scope = rememberCoroutineScope()
  return remember { YandexMapHandler(scope, editable, onPointDragEnd) }
}

@Composable
fun YandexMap(
  handler: YandexMapHandler,
  center: Coords,
  zoom: Int?,
  wayPoints: List<WayPoint>,
  modifier: Modifier = emptyModifier(),
  scrollZoom: Boolean = true,
  onPointClick: (Int) -> Unit = {},
  onSearchResult: (Location) -> Unit = {},
) {
  LazyColumn(modifier { 
    classes("map")
    style { 
      display(Block)
    }
  } + modifier) {
    DisposableEffect(Unit) {
      handler.render(scopeElement, center, zoom, wayPoints, scrollZoom, onPointClick, onSearchResult)
      onDispose {
        handler.destroy()
      }
    }
  }
}

private inline fun <T> CompletableDeferred<T>.complete(block: () -> T): T {
  try {
    return block()
      .also { complete(it) }
  } catch (e: Throwable) {
    completeExceptionally(e)
    throw e
  }
}