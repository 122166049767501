package tripper.trips

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import tripper.component1
import tripper.components.Section
import tripper.modifier
import tripper.onLoaded
import tripper.users.UserRef

@Composable
fun Trips(
  followings: Boolean = false,
  favorites: Boolean = false,
  authorId: UserRef.Id? = null,
  tabs: List<@Composable () -> Unit> = emptyList(),
  viewModel: TripsViewModel = TripsViewModel.remember(followings = followings, favorites = favorites, authorId = authorId),
) = Section(modifier{ classes("trips") }, tabs) {
  val (tripsLoading) = viewModel.trips.collectAsState()
  tripsLoading.onLoaded { trips ->
    InfiniteScrollTrips(trips, loadMore = viewModel::loadMore) { trip ->
      TripCardView(trip, short = true, controls = ViewControls(trip, short = true))
    }
  }
}