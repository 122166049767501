package tripper.files

import kotlinx.coroutines.suspendCancellableCoroutine
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.DataView
import org.w3c.files.File
import org.w3c.files.FileReader
import tripper.InternalException
import tripper.Messages
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException

//todo change to upload large files
open class FileReaderAdapter(private val chunkSize: Int, private val messages: Messages) {

  open suspend fun read(file: File): ByteArray = suspendCancellableCoroutine { continuation ->
    if (file.size.toLong() > Int.MAX_VALUE) {
      continuation.resumeWithException(InternalException(messages.somethingWentWrongError()))
    }
    
    fun readBlock(accumulator: ByteArray) {
      if (accumulator.size >= file.size.toInt()) {
        continuation.resume(accumulator)
        return
      }
      val reader = FileReader()

      reader.addEventListener("load", {
        val data = DataView(reader.result as ArrayBuffer).let { ByteArray(it.byteLength, it::getUint8) }

        readBlock(accumulator + data)
      })

      reader.addEventListener("error", {
        //todo проверить
        continuation.resumeWithException(InternalException(messages.somethingWentWrongError(), Exception(reader.error as String)))
      })

      continuation.invokeOnCancellation { reader.abort() }

      reader.readAsArrayBuffer(file.slice(accumulator.size, accumulator.size + chunkSize))
    }

    readBlock(ByteArray(0))
  }
}