package tripper.favorites

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import tripper.comments.ContentId

class FavoriteService(private val client: HttpClient) {
  
  suspend fun favorite(contentId: ContentId): Favorite? = client.get("favorites?contentId=$contentId").body<List<Favorite>>().firstOrNull()
  
  suspend fun save(favorite: Favorite): Favorite {
    val id = client.post("favorites") { setBody(favorite) }.body<String>()
    return favorite.copy(rawId = id)
  }
  
  suspend fun delete(favorite: Favorite) {
    client.delete("favorites/${favorite.id}")
  }
}