package tripper.trips

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import tripper.domain.Pagination
import tripper.users.UserRef

open class TripService(private val client: HttpClient) {
  
  open suspend fun trip(id: String): Trip {
    return client.get("trips/$id").body()
  }

  open suspend fun save(trip: Trip): Trip {
    val id = client.put("trips") { setBody(trip) }.body<String>()
    return trip.copy(rawId = id)
  }

  open suspend fun trips(
    pagination: Pagination,
    authorId: UserRef.Id? = null,
    followings: Boolean? = null,
    favorites: Boolean? = null,
  ): List<Trip> {
    return client.get("trips") {
      parameter("lastDate", pagination.fields.lastDate)
      parameter("authorId", authorId)
      parameter("followings", followings)
      parameter("favorites", favorites)
      parameter("sort", pagination.sort)
      parameter("limit", pagination.limit)
    }.body()
  }

  suspend fun delete(trip: Trip) {
    client.delete("trips/${trip.id}")
  }
}