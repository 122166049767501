package tripper.coroutines

import androidx.compose.runtime.*
import io.ktor.utils.io.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.lighthousegames.logging.logging
import tripper.ApplicationException
import tripper.LocalDependencies
import tripper.LocaleViewModel
import tripper.Messages
import tripper.notifications.NotificationViewModel
import tripper.validation.FieldValidationException

@Composable
fun rememberCoroutineScope(
  notificationViewModel: NotificationViewModel = LocalDependencies.current.notificationViewModel,
  localeViewModel: LocaleViewModel = LocalDependencies.current.localeViewModel,
): SafeCoroutineScope {
  return SafeCoroutineScope(androidx.compose.runtime.rememberCoroutineScope(), notificationViewModel, localeViewModel.messages)
}

//todo Probably, we need safe Flow#collectLatest instead of this
class SafeCoroutineScope(
  val delegate: CoroutineScope,
  private val notificationViewModel: NotificationViewModel,
  private val messages: StateFlow<Messages>,
) {
  
  fun launch(block: suspend () -> Unit) = delegate.launch {
    try {
      block()
    } catch (e: Throwable) {
      when(e) {
        is CancellationException -> throw e
        is FieldValidationException -> {}
        is ApplicationException -> notificationViewModel.notify(e.message ?: messages.value.somethingWentWrongError())
        else -> {
          //todo log exception in backend
          log.error(e) { "Unhandled error" }
          notificationViewModel.notify(messages.value.somethingWentWrongError())
        }
      }
    }
  }
  
  @Composable
  fun <T> produceState(initialValue: T, key: Any? = null, block: suspend () -> T): State<T> {
    val state = remember { mutableStateOf(initialValue) }
    LaunchedEffect(this, key) {
      state.value = block()
    }
    return state
  }
  
  companion object {
    private val log = logging(SafeCoroutineScope::class.simpleName)
  }
}

@Composable
fun LaunchedEffect(scope: SafeCoroutineScope, key: Any?, block: suspend () -> Unit) {
  var job by remember { mutableStateOf<Job?>(null) }
  remember(key) {
    job?.cancel("Cancel previous job")
    job = scope.launch(block)
    job?.invokeOnCompletion { job = null }
  }
}