package tripper.files

import androidx.compose.runtime.Composable
import io.ktor.http.*
import io.ktor.http.ContentType.*
import tripper.domain.Url

expect class ImageConverter {
  
  fun compress(file: FileData): ImageConvertProgress
}

val Image.WEBP get() = ContentType("image", "webp")

@Composable
expect fun rememberImageConverter(): ImageConverter

interface ImageConvertProgress {
  fun onPreLoad(block: (Url) -> Unit): ImageConvertProgress
  fun onCompressed(block: (Url) -> Unit): ImageConvertProgress
  suspend fun result(): FileData
}