package tripper.notifications

import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.unit.IntOffset
import kotlinx.coroutines.delay
import tripper.components.Popup
import tripper.components.Text
import tripper.coroutines.LaunchedEffect
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

@Composable
fun NotificationPopup(message: String, duration: Duration = 2.seconds, scope: SafeCoroutineScope = rememberCoroutineScope()) {
  var shown by remember { mutableStateOf(true) }
  
  if(shown) {
    Popup("notification", Alignment.BottomEnd, IntOffset(-20, -20)) {
      Text(message)
    }
  }
  
  LaunchedEffect(scope, Unit) {
    delay(duration)
    shown = false
  }
}