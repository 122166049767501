package tripper.components

import androidx.compose.runtime.*
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.dom.Div
import tripper.LocalDependencies
import tripper.RequestViewModel
import tripper.coroutines.LaunchedEffect
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import kotlin.text.Typography.nbsp
import kotlin.time.Duration
import kotlin.time.Duration.Companion.milliseconds

@Composable
fun RequestLoadingBar(
  requestViewModel: RequestViewModel = LocalDependencies.current.requestViewModel,
  scope: SafeCoroutineScope = rememberCoroutineScope(),
  delay: Duration = 10.milliseconds,
) {
  val activeRequests = requestViewModel.activeRequests.collectAsState()
  val shouldShow by remember { derivedStateOf { activeRequests.value.isNotEmpty() } }

  if (shouldShow) {
    var showAfterDelay by remember { mutableStateOf(false) }
    LaunchedEffect(scope, Unit) {
      delay(delay)
      showAfterDelay = true
    }
    if (showAfterDelay) LoadingBar()
  }
}

@Composable
private fun LoadingBar() {
  Div({
    classes("loading-bar")
  }) { 
    Div({
      classes("value")
    }) {
      Text(nbsp.toString())
    }
  }
}